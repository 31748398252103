import dynamic from 'next/dynamic';

import { useSetPageProtected } from '@collab/providers/PageProtectedProvider';

import { ContentPageProps, PageMetaProps } from 'libs/cms/parse/types';

const MdDocPage = dynamic(() => import('pages/mdx/MdDocPage'));
const MdxDocPage = dynamic(() => import('pages/mdx/MdxDocPage'));
const ContentPage = dynamic(() => import('pages/ContentPage'));

export type CmsPageProps = ContentPageProps & { pathPrefix?: string };

const CmsPage: React.FC<CmsPageProps> = (props) => {
  useSetPageProtected(props.isProtected);

  if (props.type === 'json') {
    return (
      <ContentPage
        content={props.content}
        pageMeta={props.meta}
        fullPath={props.fullPath}
        pathPrefix={props.pathPrefix}
      />
    );
  }

  if (props.type === 'mdx') {
    return (
      <MdxDocPage
        mdxContent={props.mdxContent}
        seo={getSeo(props.meta)}
        repoUrl={props.meta.repoUrl}
      />
    );
  }

  return (
    <MdDocPage
      mdxContent={props.mdxContent}
      seo={getSeo(props.meta)}
      repoUrl={props.meta.repoUrl}
    />
  );
};

const getSeo = (pageMeta: PageMetaProps) => ({
  title: pageMeta.title,
  category: pageMeta.category,
  description: pageMeta.seo.description,
  image: pageMeta.seo.image,
});

export default CmsPage;
