import { GetStaticPaths, GetStaticProps } from 'next';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';
import path from 'path';

import logger from '@collab/libs/server/logger';
import { fileExists } from '@collab/utils/files';

import { generatedPagesFolder } from 'libs/cms/common/paths';
import getPagePaths from 'libs/cms/parse/getPagePaths';
import getPageProps from 'libs/cms/parse/getPageProps';
import { parseSlug, toNextPath } from 'libs/cms/parse/slugUtils';
import CmsPage, { CmsPageProps } from 'pages/CmsPage';
import { MissingParams } from 'utils/slugs';

export const getStaticPaths: GetStaticPaths = async () => {
  const paths = await getNonDocsPaths();

  return {
    // Adding undefined for landing page
    paths: [...paths, undefined].map(toNextPath),
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps<CmsPageProps> = async ({
  params,
}) => {
  if (!params) throw new MissingParams();

  const { fullPath } = parseSlug(params.slug ?? 'index');
  const pagePath = await getPagePath(fullPath);

  if (pagePath) {
    const props = await getPageProps(pagePath, fullPath);

    return { props };
  } else {
    logger.warn(`Unknown params at runtime ${JSON.stringify(params)}`);
    return { notFound: true };
  }
};

const getPagePath = async (fullPath: string): Promise<string | false> => {
  const pagePath = path.join(generatedPagesFolder, `${fullPath}.json`);
  const pageExists = await fileExists(pagePath);

  // If path doesn't exist, we assume it's the index file.
  return pageExists ? pagePath : getPageIndexPath(fullPath);
};

const getPageIndexPath = async (fullPath: string): Promise<string | false> => {
  const pagePath = path.join(generatedPagesFolder, fullPath, 'index.json');
  const pageExists = await fileExists(pagePath);

  if (!pageExists) {
    if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
      throw new PageNotFound(fullPath);
    }

    return false;
  }

  return pagePath;
};

const getNonDocsPaths = async () => {
  const pagePaths = await getPagePaths(generatedPagesFolder);

  return pagePaths.filter(
    (path) => !path.startsWith('apis/docs/') && !path.startsWith('news'),
  );
};

class PageNotFound extends Error {
  constructor(path: string) {
    super(`Page with path: '${path}' not found.`);
  }
}

export default CmsPage;
